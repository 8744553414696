import { postWeb } from "helpers/api_helper"
import _ from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import { withTranslation } from "react-i18next"
import { MetaTags } from "react-meta-tags"
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Form,
} from "reactstrap"
import toastr from "toastr"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Skeleton from "react-loading-skeleton"
import { useSelector } from "react-redux"
import moment from "moment"

const Details = ({ field, value }) => (
  <div className="d-flex align-items-center justify-content-between w-full mt-2">
    <strong className="fs-5">{field}:</strong> {value}
  </div>
)

const AccidentReports = props => {
  const { users } = useSelector(state => ({
    users: state.Users.users,
  }))
  const [userData, setUserData] = useState({})
  const [accidents, setAccidents] = useState([])
  const [modal, setModal] = useState(false)
  const [currentAccident, setCurrentAccident] = useState(null)
  const [currentStatus, setCurrentStatus] = useState("") // Store status for editing
  const [dateRange, setDateRange] = useState(null)
  const [severityFilter, setSeverityFilter] = useState("")
  const [statusFilter, setStatusFilter] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalAccidents, setTotalAccidents] = useState(0)
  const [isLoading, setLoading] = useState(true)
  const [imageModal, setImageModal] = useState(false)
  const [galleryImages, setGalleryImages] = useState([])
  const [selectedImage, setSelectedImage] = useState(null)

  const toggleImageModal = (images = []) => {
    setGalleryImages(images)
    setSelectedImage(images[0] || null) // Default to the first image
    setImageModal(!imageModal)
  }
  const fetchAccidents = async () => {
    setLoading(true)
    if (dateRange != null && (!dateRange?.start || !dateRange.end)) {
      setLoading(false)
      toastr.error("Start and End both date ranges are required")
    }

    try {
      const response = await postWeb("/logistics/listAccidentReports", {
        page: currentPage,
        pageSize: rowsPerPage,
        dateRange,
        severityFilter,
        statusFilter,
        web: true,
      })
      if (response?.statusCode == 200) {
        setAccidents(response?.records)
        setTotalAccidents(response?.totalRecords)
      } else {
        toastr.error(response.msg)
      }
    } catch (error) {
      console.log("ERRR", error)
      toastr.error("Error fetching accidents")
    } finally {
      setLoading(false)
    }
  }

  const handlePageChange = page => setCurrentPage(page)
  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage)
    setCurrentPage(page)
  }

  const toggleModal = (accident = null) => {
    setCurrentAccident(accident)
    setCurrentStatus(accident?.status || "") // Set current status for editing
    setModal(!modal)
  }

  const handleStatusChange = async () => {
    setLoading(true)
    try {
      const response = await postWeb("/logistics/updateReportStatus", {
        reportId: currentAccident._id,
        status: currentStatus,
      })
      if (response?.statusCode == 200) {
        toastr.success("Accident status updated successfully")
        fetchAccidents() // Re-fetch accidents after update
        toggleModal() // Close the modal after saving
      } else {
        toastr.error(response.msg)
      }
    } catch (error) {
      toastr.error("Error updating accident status")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0])
    }
  }, [users])

  useEffect(() => {
    if (!dateRange?.start && !dateRange?.end) {
      setDateRange(null)
    }
    fetchAccidents()
  }, [currentPage, rowsPerPage, dateRange, severityFilter, statusFilter])

  const handleFilterChange = (newFilters) => {
    setSeverityFilter(newFilters.severity !== undefined ? newFilters.severity : severityFilter);
    setStatusFilter(newFilters.status !== undefined ? newFilters.status : statusFilter); 
    setDateRange(newFilters.dateRange !== undefined ? newFilters.dateRange : dateRange);  
    setCurrentPage(1);
  };


  const columns = [
    { name: "Accident ID", selector: "accidentId", sortable: true, center:true },
    {
      name: "Date of Accident",
      cell: row => moment(row?.dateTime).format("YYYY-MM-DD HH:mm:ss"),
      sortable: true,
    },
    {
      name: "Severity Level",
      // center: true,
      cell: row => {
        const severity = row?.severity
        const baseStyle = {
          color: "white",
          padding: "5px 10px",
          borderRadius: "5px",
          fontSize: "10px",
        };
        let severityStyle;
        let severityLabel;

        switch (severity) {
          case "major":
            severityStyle = { ...baseStyle, backgroundColor: "#FF9029" };
            severityLabel = "Major";
            break;
          case "critical":
            severityStyle = { ...baseStyle, backgroundColor: "#FF2929" };
            severityLabel = "Critical";
            break;
          case "minor":
            severityStyle = { ...baseStyle, backgroundColor: "#0AD200" };
            severityLabel = "Minor";
            break;
          default:
            severityStyle = { ...baseStyle, backgroundColor: "gray" };
            severityLabel = _.capitalize(severity || "Unknown");
            break;
        }

        return <span style={severityStyle}>{severityLabel}</span>;
      },
      sortable: true,
    },
    { name: "Driver Name", cell: row => row?.driver?.name, sortable: true },
    {
      name: "Vehicle",
      cell: row =>
        row?.vehicle
          ? `${row?.vehicle?.type} - ${row?.vehicle?.model} (${row?.vehicle?.number})`
          : "N/A",
      sortable: true,
    },
    {
      name: "Status",
      center: true,
      cell: row => {
        const status = row?.status
        const baseStyle = {
          color: "white",
          padding: "5px 10px",
          borderRadius: "5px",
          fontSize: "10px",
        };
        let statusStyle;
        let statusLabel;

        switch (status) {
          case "reported":
            statusStyle = { ...baseStyle, backgroundColor: "#FF9029" };
            statusLabel = "Reported";
            break;
          case "investigating":
            statusStyle = { ...baseStyle, backgroundColor: "#FFDB29" };
            statusLabel = "Investigating";
            break;
          case "resolved":
            statusStyle = { ...baseStyle, backgroundColor: "#0AD200" };
            statusLabel = "Resolved";
            break;
          case "declined":
            statusStyle = { ...baseStyle, backgroundColor: "#FF2929" };
            statusLabel = "Declined";
            break;
          default:
            statusStyle = { ...baseStyle, backgroundColor: "gray" };
            statusLabel = _.capitalize(status || "Unknown");
            break;
        }

        return <span style={statusStyle}>{statusLabel}</span>;
      },
      sortable: true
    },
    {
      name: "Actions",
      cell: row => (
        <Button
          color="info"
          size="sm"
          disabled={isLoading}
          onClick={() => toggleModal(row)}
        >
          View Details
        </Button>
      ),
    },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Masters | Accident Reports</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Masters")}
            breadcrumbItem={props.t("Accident Reports")}
          />
          <div className="mb-4 d-flex align-items-center w-full justify-content-between">
            <div className="d-flex align-items-center w-75">
              <FormGroup className="me-3 w-25">
                <Label for="severityFilter">Severity</Label>
                <Input
                  disabled={isLoading}
                  type="select"
                  id="severityFilter"
                  value={severityFilter}
                  onChange={e => handleFilterChange({ severity: e.target.value })}
                >
                  <option value="">All</option>
                  <option value="minor">Minor</option>
                  <option value="major">Major</option>
                  <option value="critical">Critical</option>
                </Input>
              </FormGroup>

              <FormGroup className="me-3 w-25">
                <Label for="statusFilter">Status</Label>
                <Input
                  disabled={isLoading}
                  type="select"
                  id="statusFilter"
                  value={statusFilter}
                  onChange={e => handleFilterChange({ status: e.target.value })}
                >
                  <option value="">All</option>
                  <option value="reported">Reported</option>
                  <option value="investigating">Investigating</option>
                  <option value="resolved">Resolved</option>
                  <option value="declined">Declined</option>
                </Input>
              </FormGroup>

              <FormGroup className="me-3 w-25">
                <Label for="startDate">Start Date</Label>
                <Input
                  disabled={isLoading}
                  type="date"
                  id="startDate"
                  value={dateRange?.start}
                  onChange={e => handleFilterChange({ dateRange: { ...dateRange, start: e.target.value } })}
                />
              </FormGroup>

              <FormGroup className="w-25">
                <Label for="endDate">End Date</Label>
                <Input
                  disabled={isLoading}
                  type="date"
                  id="endDate"
                  value={dateRange?.end}
                  onChange={e => handleFilterChange({ dateRange: { ...dateRange, end: e.target.value } })}
                />
              </FormGroup>

            </div>
          </div>

          <DataTable
            columns={columns}
            data={accidents}
            pagination
            paginationPerPage={rowsPerPage}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            paginationDefaultPage={currentPage}
            onChangePage={handlePageChange}
            paginationTotalRows={totalAccidents}
            onChangeRowsPerPage={handleRowsPerPageChange}
            progressPending={isLoading}
            progressComponent={<Skeleton height={50} count={5} />}
            paginationServer
          />

          {/* View Accident Details Modal */}
          {currentAccident && (
            <Modal isOpen={modal} toggle={() => toggleModal(null)}>
              <ModalHeader toggle={() => toggleModal(null)}>
                Accident Details
              </ModalHeader>
              <ModalBody>
                <Details
                  field={"Location"}
                  value={<a
                    className="btn btn-link"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://maps.google.com/?q=${currentAccident?.location?.lat || ""},${currentAccident?.location?.long || ""}`}
                  >Location</a>}
                />
                <Details field={"Severity"} value={currentAccident?.severity} />
                <Details
                  field={"Driver Name"}
                  value={currentAccident?.driver?.name}
                />
                <Details
                  field={"Vehicle"}
                  value={
                    currentAccident?.vehicle
                      ? `${currentAccident?.vehicle?.type} - ${currentAccident?.vehicle?.model} (${currentAccident?.vehicle?.number})`
                      : "N/A"
                  }
                />
                <Details
                  field={"Description"}
                  value={currentAccident.description}
                />

                <Details
                  field={"Reported at"}
                  value={moment(currentAccident?.dateTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                />

                <div className="d-flex align-items-center justify-content-between w-full ">
                  <strong>Status:</strong>
                  <Input
                    type="select"
                    // id="status"
                    value={currentStatus}
                    onChange={e => setCurrentStatus(e.target.value)}
                    className="w-50"
                    disabled={isLoading}
                  >
                    <option disabled value="reported">Reported</option>
                    <option value="investigating">Investigating</option>
                    <option value="resolved">Resolved</option>
                    <option value="declined">Declined</option>
                  </Input>
                </div>
                {currentAccident?.images?.length > 0 && (
                  <div className="d-flex align-items-center justify-content-between w-full mt-2">
                    <strong>Images: </strong>
                    <Button
                      color="success"
                      outline
                      size="md"
                      className="w-50"
                      onClick={() => toggleImageModal(currentAccident?.images)}
                    >
                      View Images
                    </Button>
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  disabled={isLoading}
                  color="secondary"
                  onClick={() => toggleModal(null)}
                >
                  Close
                </Button>
                <Button
                  disabled={isLoading}
                  color="primary"
                  onClick={handleStatusChange}
                >
                  Save Changes
                </Button>
              </ModalFooter>
            </Modal>
          )}
          <Modal
            isOpen={imageModal}
            toggle={() => toggleImageModal([])}
            size="lg"
          >
            <ModalHeader toggle={() => toggleImageModal([])}>
              Accident Images
            </ModalHeader>
            <ModalBody>
              {selectedImage && (
                <div className="mb-4">
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="w-100 rounded"
                    style={{ maxHeight: "400px", objectFit: "contain" }}
                  />
                </div>
              )}
              <div className="d-flex gap-2 flex-wrap">
                {galleryImages.map((img, index) => (
                  <img
                    loading={<Skeleton height={50} width={50} />}
                    key={index}
                    src={img}
                    alt={`Accident image ${index + 1}`}
                    className={`thumbnail rounded border ${img === selectedImage ? "border-primary" : ""
                      }`}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                    onClick={() => setSelectedImage(img)}
                  />
                ))}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => toggleImageModal([])}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

AccidentReports.propTypes = {
  t: PropTypes.any,
}
Details.propTypes = {
  field: PropTypes.any,
  value: PropTypes.any,
}

export default withTranslation()(AccidentReports)

import { postWeb } from 'helpers/api_helper';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import toastr from 'toastr';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
const phCode = process.env.REACT_APP_PHONE_PREFIX || 966;

const Vendors = (props) => {
  const { users } = useSelector(state => ({
    users: state.Users.users
  }));
  const initailStateOfErrors = {
    mobile: '',
  }
  const initalValueForStatusFilters = false
  const [error, setError] = useState(initailStateOfErrors);
  const [userData, setUserData] = useState({});
  const [modal, setModal] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    type: '',
    vatNumber: '',
    address: '',
    status: true,
  });
  const [editIndex, setEditIndex] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteModal, setDeleteModal] = useState(false);
  const [vendorToDelete, setVendorToDelete] = useState(null);
  const [totalVendors, setTotalVendors] = useState(0);
  const [statusDisabled, setStatusDisabled] = useState(false);
  const [statusEnabled, setStatusEnabled] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [vendorTypes, setVendorTypes] = useState([])

  const hasCreatePermission = () => userData?.permissions?.allPermissions === true || userData?.permissions?.vendor.create === true;
  const hasUpdatePermission = () => userData?.permissions?.allPermissions === true || userData?.permissions?.vendor.update === true;

  // Toggle Modal
  const toggleModal = () => setModal(!modal);
  const toggleDeleteModal = (vendor) => {
    setVendorToDelete(vendor);
    setDeleteModal(!deleteModal);
  };

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users]);

  // Handle Input Change
  const handleChange = (e) => {
    setError(initailStateOfErrors)
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const fetchVendors = async () => {
    setLoading(true);
    try {
      const response = await postWeb('/vendors/search', {
        page: currentPage,
        pageSize: rowsPerPage,
        searchText,
        statusDisabled,
        statusEnabled
      });
      if (response?.statusCode == 200) {
        setVendors(response.data);
        setTotalVendors(response.totalVendors);
        setVendorTypes(response?.types || [])
      } else {
        toastr.error(response.msg);
      }
    } catch (error) {
      toastr.error('Error fetching vendors');
    } finally {
      setLoading(false);
    }
  };

  const validateMobile = (number) => {
    let regex;
    let valid = true
    if (phCode == 91) {
      // India: 10 digits, starting with 6, 7, 8, or 9
      regex = /^[6-9]\d{9}$/;
    } else if (phCode == 966) {
      // Saudi Arabia: 9 digits, or 10 if starting with 0
      regex = /^0?\d{9}$/;
    } else {
      // Handle other cases or default to invalid
      valid = false
      setError({ ...error, mobile: "Invalid country code." });
    }

    if (number === "" || regex.test(number)) {
      valid = true
    } else {
      valid = false
      setError(
        {
          ...error, mobile: phCode == 91
            ? "Mobile number must be 10 digits long and start with 6, 7, 8, or 9."
            : "Mobile number must be 9 digits long, or 10 if starting with 0."
        }
      );
    }

    return valid
  };
  // Handle Add or Edit Vendor
  const handleSave = async () => {
    setLoading(true);
    try {
      const payload = { ...form };
      let isValidPhone = await validateMobile(form.phone)
      if (!isValidPhone) {
        setLoading(false)
        return
      }
      if (!form.name || !form.phone || !form.type || !form.email) {
        toastr.error("All fields are required!")
        setLoading(false)
        return
      }
      let response;
      if (editIndex !== null) {
        payload.vendorId = vendors[editIndex]._id;
        response = await postWeb('/vendors/update', payload);
      } else {
        response = await postWeb('/vendors/create', payload);
      }

      if (response?.statusCode == 200) {
        fetchVendors();
        toastr.success(response.msg);
      } else {
        toastr.error(response.msg);
      }

      resetForm();
      toggleModal();
    } catch (error) {
      toastr.error('Error saving vendor');
    } finally {
      setLoading(false);
    }
  };

  // Reset Form and Edit Index
  const resetForm = () => {
    setForm({
      name: '',
      email: '',
      phone: '',
      type: '',
      vatNumber: '',
      address: '',
      status: true,
    });
    setEditIndex(null);
  };

  // Handle Edit Button Click
  const handleEdit = (index) => {
    setForm(vendors[index]);
    setEditIndex(index);
    toggleModal();
  };

  // Handle Delete Vendor
  const handleDelete = (vendor) => {
    toggleDeleteModal(vendor);
  };

  const deleteVendor = async () => {
    setLoading(true);
    try {
      const payload = { vendorId: vendorToDelete._id };
      const response = await postWeb('/vendors/delete', payload);
      if (response?.statusCode == 200) {
        fetchVendors();
        toastr.success(response.msg);
      } else {
        toastr.error(response.msg);
      }
    } catch (error) {
      toastr.error('Error deleting vendor');
    } finally {
      setLoading(false);
      setDeleteModal(false);
    }
  };

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };

  // Columns for DataTable
  const columns = [
    {
      name: '#',
      selector: (row, index) => index + 1,
      width: '50px',
    },
    {
      name: 'Vendor Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Phone',
      selector: 'phone',
      sortable: true,
    },
    {
      name: 'Vendor Type',
      selector: 'type',
      sortable: true,
    },
    {
      name: 'Status',
      sortable: true,
      selector: (row) => (row.status ? 'Active' : 'Inactive'),
    },
    ...(
      hasUpdatePermission() === true
        ? [
          {
            name: 'Actions',
            cell: (row, index) => (
              <>
                <Button
                  disabled={isLoading}
                  color="warning"
                  size="sm"
                  className="me-2"
                  onClick={() => handleEdit(index)}
                >
                  <i className="bx bx-edit-alt"></i>
                </Button>
                <Button
                  disabled={isLoading}
                  color="danger"
                  size="sm"
                  onClick={() => handleDelete(row)}
                >
                  <i className="bx bx-trash"></i>
                </Button>
              </>
            ),
          },
        ]
        : []
    ),
  ];

  const debouncedFetchVendors = _.debounce(fetchVendors, 800);

  useEffect(() => {
    fetchVendors();
  }, []);

  useEffect(() => {
    if (searchText || statusDisabled != initalValueForStatusFilters || statusEnabled != initalValueForStatusFilters) {
      setCurrentPage(1);
      setRowsPerPage(10);
      debouncedFetchVendors();
      return () => {
        debouncedFetchVendors.cancel();
      };
    } else {
      fetchVendors();
    }
  }, [searchText, currentPage, rowsPerPage, statusDisabled, statusEnabled]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Masters | Vendor</title>
        </MetaTags>

        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Masters")}
            breadcrumbItem={props.t("Vendor Master")}
          />
          {hasCreatePermission() === true && <Button disabled={isLoading} className="mb-4" color="primary" onClick={toggleModal} style={{ float: 'right' }}>
            Add New Vendor
          </Button>}
          <div className="mb-4 d-flex align-items-center w-full justify-content-between">
            <div className="w-50">
              <Input
                disabled={isLoading}
                type="text"
                placeholder="Search by Name, Email, or Phone"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className='w-full p-2 border rounded'
              />
            </div>
            <div className='d-flex  align-items-center'>
              <div className="d-flex align-items-center">
                <span className={`me-2 ${statusEnabled ? "text-success font-weight-semibold" : ""}`}>Show Active</span>
                <div className="form-check form-switch form-switch-lg">
                  <input
                    disabled={isLoading}
                    type="checkbox"
                    className="form-check-input form-slider-color-green"
                    defaultChecked={statusEnabled}
                    onChange={(e) => { setStatusEnabled(!statusEnabled) }}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className={`me-2 ${statusDisabled ? "text-success font-weight-semibold" : ""}`}>Show Inactive</span>
                <div className="form-check form-switch form-switch-lg">
                  <input
                    disabled={isLoading}
                    type="checkbox"
                    className="form-check-input form-slider-color-green"
                    defaultChecked={statusDisabled}
                    onChange={(e) => { setStatusDisabled(!statusDisabled) }}
                  />
                </div>
              </div>
            </div>
          </div>

          <DataTable
            columns={columns}
            data={vendors}
            pagination
            paginationPerPage={rowsPerPage}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            paginationDefaultPage={currentPage}
            onChangePage={handlePageChange}
            paginationTotalRows={totalVendors}
            onChangeRowsPerPage={handleRowsPerPageChange}
            progressPending={isLoading}
            progressComponent={<Skeleton height={50} count={5} />}
            paginationServer
          />

          {/* Modal for Add/Edit Vendor */}
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Add New Vendor</ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup className="mb-3">
                  <Label for="vendorName">Vendor Name</Label>
                  <Input
                    disabled={isLoading}
                    type="text"
                    name="name"
                    id="vendorName"
                    placeholder="Enter vendor name"
                    value={form.name}
                    onChange={handleChange}
                  />
                </FormGroup>

                <FormGroup className="mb-3">
                  <Label for="vendorEmail">Email</Label>
                  <Input
                    disabled={isLoading}
                    type="email"
                    name="email"
                    id="vendorEmail"
                    placeholder="Enter vendor email"
                    value={form.email}
                    onChange={handleChange}
                  />
                </FormGroup>

                <FormGroup className="mb-3">
                  <Label for="vendorPhone">Phone</Label>
                  <Input
                    disabled={isLoading}
                    type="text"
                    name="phone"
                    id="vendorPhone"
                    placeholder="Enter vendor phone"
                    value={form.phone}
                    onChange={handleChange}
                    invalid={error?.mobile.length ? true : false}
                  />
                  {error?.mobile.length ? <div className="text-danger">{error.mobile}</div> : null}
                </FormGroup>

                <FormGroup className="mb-3">
                  <Label for="vendorType">Vendor Type</Label>
                  <Input
                    disabled={isLoading}
                    type="select"
                    name="type"
                    id="vendorType"
                    value={form.type}
                    onChange={handleChange}
                  >
                    <option value={''}>Choose</option>
                    {vendorTypes.map(type => (
                      <option key={type} value={type}>{type}</option>
                    ))}
                  </Input>
                </FormGroup>

                <FormGroup className="mb-3">
                  <Label for="vendorVatNumber">VAT Number</Label>
                  <Input
                    disabled={isLoading}
                    type="text"
                    name="vatNumber"
                    id="vendorVatNumber"
                    placeholder="Enter vendor VAT number"
                    value={form.vatNumber}
                    onChange={handleChange}
                  />
                </FormGroup>

                <FormGroup className="mb-3">
                  <Label for="vendorAddress">Address</Label>
                  <Input
                    disabled={isLoading}
                    type="text"
                    name="address"
                    id="vendorAddress"
                    placeholder="Enter vendor address"
                    value={form.address}
                    onChange={handleChange}
                  />
                </FormGroup>

                <FormGroup className="mb-3">
                  <Label for="vendorStatus">Status</Label>
                  <Input
                    disabled={isLoading}
                    type="select"
                    name="status"
                    id="vendorStatus"
                    value={form.status}
                    onChange={handleChange}
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </Input>
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button disabled={isLoading} color="secondary" onClick={toggleModal}>Cancel</Button>
              <Button disabled={isLoading} color="primary" onClick={handleSave}>Save</Button>
            </ModalFooter>
          </Modal>

          {/* Delete Confirmation Modal */}
          <Modal isOpen={deleteModal} toggle={() => setDeleteModal(!deleteModal)}>
            <ModalHeader toggle={() => setDeleteModal(!deleteModal)}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              <p>Are you sure you want to delete this vendor?</p>
              <p><strong>{vendorToDelete?.name} ({vendorToDelete?.email})</strong></p>
            </ModalBody>
            <ModalFooter>
              <Button disabled={isLoading} color="secondary" onClick={() => setDeleteModal(false)}>Cancel</Button>
              <Button
                disabled={isLoading}
                color="danger"
                onClick={deleteVendor}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>

        </div>
      </div>
    </React.Fragment>
  );
};

Vendors.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Vendors);

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import DataTable from "react-data-table-component"
import { MetaTags } from "react-meta-tags"
import { Button, Input, Label, FormGroup, Form } from "reactstrap"
import toastr from "toastr"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Skeleton from "react-loading-skeleton"
import { postWeb } from "helpers/api_helper"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { getVehicles } from "store/actions"

const PartChanges = props => {
  const [partChanges, setPartChanges] = useState([])
  const [dateRange, setDateRange] = useState({ start: '', end: '' })
  const [vehicleFilter, setVehicleFilter] = useState("")
  const [vehicleTypeFilter, setVehicleTypeFilter] = useState("")
  const [serviceProviderFilter, setServiceProviderFilter] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPartChanges, setTotalPartChanges] = useState(0)
  const [isLoading, setLoading] = useState(true)
  const [vehicles, setVehicles] = useState([])
  const [vendors, setVendors] = useState([])

  const getAllVehicles = async () => {
    const response = await postWeb('/vehicles/search', { all: true })

    if (response?.statusCode == 200) {
      setVehicles(response?.data || [])
    }
  }

  const getAllVendors = async () => {
    const response = await postWeb('/vendors/search', { all: true })

    if (response?.statusCode == 200) {
      setVendors(response?.data || [])
    }
  }

  const fetchPartChanges = async () => {
    setLoading(true)
    if ((dateRange?.start != '' && dateRange?.end != '') && (!dateRange?.start || !dateRange.end)) {
      setLoading(false)
      toastr.error("Start and End both date ranges are required")
      return
    }

    try {
      const response = await postWeb("/logistics/listPartChanges", {
        page: currentPage,
        pageSize: rowsPerPage,
        dateRange,
        vehicle: vehicleFilter,
        type: vehicleTypeFilter,
        serviceProvider: serviceProviderFilter,
        web: true,
      })
      if (response?.statusCode == 200) {
        setPartChanges(response?.records)
        setTotalPartChanges(response?.totalRecords)
      } else {
        toastr.error(response.msg)
      }
    } catch (error) {
      toastr.error("Error fetching part changes")
    } finally {
      setLoading(false)
    }
  }

  const handlePageChange = page => setCurrentPage(page)
  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage)
    setCurrentPage(page)
  }

  useEffect(() => {
    getAllVendors()
    getAllVehicles()
  }, [])

  useEffect(() => {
    fetchPartChanges()
  }, [currentPage, rowsPerPage, dateRange, vehicleFilter, vehicleTypeFilter, serviceProviderFilter])

  const columns = [
    {
      name: 'Part ID',
      selector: (row, index) => row?.partChangeId || 'N/A',
      center: true
    },
    {
      name: "Vehicle", selector: row => row?.vehicle
        ? `${row?.vehicleDetails?.model} (${row?.vehicleDetails?.number})`
        : "N/A", sortable: true, wrap: true
    },
    {
      name: "Type", selector: row => row?.vehicle
        ? row?.vehicleDetails?.type
        : "N/A", sortable: true, wrap: true
    },
    { name: "Part Name", selector: row => row?.partName, sortable: true },
    {
      name: "Change Date",
      selector: row => moment(row?.changeDate).format('YYYY-MM-DD'),
      sortable: true,
    },
    { name: "Cost", selector: row => `${row?.cost?.toFixed(2)}`, sortable: true },
    {
      name: "Service Provider",
      selector: row => row?.vendorDetails?.name || 'N/A',
      sortable: true,
    },
    {
      name: "Receipt",
      selector: row => row?.receipt ?
        <Button className='d-flex align-items-center justify-content-between' color="primary" outline size="sm" onClick={() => window.open(row?.receipt, '_blank')}>
          <span className=''>
            View
          </span>
        </Button>
        : 'Not Uploaded',
      sortable: true,
    },
    { name: "Notes", selector: row => row?.note || "N/A", sortable: true, wrap: true, center: true },
  ]

  const handleFilterChange = (newFilters) => {
    // Update the state values based on the new filters, including empty values
    setVehicleTypeFilter(newFilters.vehicleType != undefined ? newFilters.vehicleType : vehicleTypeFilter);  // Update vehicle type if provided, else keep existing
    setVehicleFilter(newFilters.vehicle != undefined ? newFilters.vehicle : vehicleFilter);  // Update vehicle filter if provided, else keep existing
    setServiceProviderFilter(newFilters.serviceProvider !=undefined ? newFilters.serviceProvider  : serviceProviderFilter);  // Update service provider filter if provided, else keep existing

    // Handle the date range filter (start and end dates)
    if (newFilters.dateRange) {
      const { start, end } = newFilters.dateRange;
      if (start || end) {
        setDateRange({
          start: start || dateRange?.start,  // Only update start date if provided
          end: end || dateRange?.end,  // Only update end date if provided
        });
      }
    }

    // Reset the current page to 1 whenever any filter is applied
    setCurrentPage(1);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Logistics | Part Changes</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Logistics")}
            breadcrumbItem={props.t("Part Changes")}
          />
          <div className="mb-4 d-flex align-items-center w-full justify-content-between">
            <div className="d-flex align-items-center w-75">
              <FormGroup className="me-3 w-25">
                <Label for="vehicleTypeFilter">Vehicle type</Label>
                <Input
                  disabled={isLoading}
                  type="select"
                  id="vehicleTypeFilter"
                  value={vehicleTypeFilter}
                  onChange={e => handleFilterChange({ vehicleType: e.target.value })}
                >
                  <option value={''}>Select</option>
                  <option value={'Car'}>Car</option>
                  <option value={'Van'}>Van</option>
                </Input>
              </FormGroup>

              <FormGroup className="me-3 w-25">
                <Label for="vehicleFilter">Vehicles</Label>
                <Input
                  disabled={isLoading}
                  type="select"
                  id="vehicleFilter"
                  value={vehicleFilter}
                  onChange={e => handleFilterChange({ vehicle: e.target.value })}
                >
                  <option value={''}>Select</option>
                  {vehicles?.map(v => (
                    <option key={v?._id} value={v?._id}>
                      {`${v?.model} (${v?.number})`}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup className="me-3 w-25">
                <Label for="serviceProviderFilter">Service Providers</Label>
                <Input
                  disabled={isLoading}
                  type="select"
                  id="serviceProviderFilter"
                  value={serviceProviderFilter}
                  onChange={e => handleFilterChange({ serviceProvider: e.target.value })}
                >
                  <option value={''}>Select</option>
                  {vendors?.map(v => (
                    <option key={v?._id} value={v?._id}>
                      {v.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup className="me-3 w-25">
                <Label for="startDate">Start Date</Label>
                <Input
                  disabled={isLoading}
                  type="date"
                  id="startDate"
                  value={dateRange?.start || ''}
                  onChange={e =>
                    handleFilterChange({
                      dateRange: { ...dateRange, start: e.target.value },
                    })
                  }
                />
              </FormGroup>

              <FormGroup className="w-25">
                <Label for="endDate">End Date</Label>
                <Input
                  disabled={isLoading}
                  type="date"
                  id="endDate"
                  value={dateRange?.end || ''}
                  onChange={e =>
                    handleFilterChange({
                      dateRange: { ...dateRange, end: e.target.value },
                    })
                  }
                />
              </FormGroup>

            </div>
          </div>

          <DataTable
            columns={columns}
            data={partChanges}
            pagination
            paginationPerPage={rowsPerPage}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            paginationDefaultPage={currentPage}
            onChangePage={handlePageChange}
            paginationTotalRows={totalPartChanges}
            onChangeRowsPerPage={handleRowsPerPageChange}
            progressPending={isLoading}
            progressComponent={<Skeleton height={50} count={5} />}
            paginationServer
          />
        </div>
      </div>
    </React.Fragment>
  )
}

PartChanges.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(PartChanges)
